<template>
  <div>
    <master-component
      :config="config"
      :showRemove="false"
      :showAdd="false"
      :showEdit="false"
    >
      <template v-slot:table-custom-event-button="{ item }">
        <custom-tooltip
          v-slot="{ on }"
          title="แก้ไข"
        >
          <v-icon
            v-on="on"
            color="info"
            @click="seeDetails(item)"
            class="mx-5"
          >mdi-launch</v-icon>
        </custom-tooltip>
      </template>
    </master-component>
  </div>
</template>


<script>
import { getLocations } from "../js/dropdown-service";
import { UserRole, InputType, DialogSize, Usr } from "../js/constants";
import { getToken } from "../js/authorization";

export default {
  data() {
    return {
      config: {},
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "master-member",
        shortName: "กิจกรรมของทีม",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    seeDetails(item, index) {
      let routeData = this.$router.resolve({
        name: "team-details",
        params: {
          teamId: item.teamId,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  computed: {
    urlSection() {
      let searchUrl = "/activity/search";
      let getUrl = "/activity";
      let addUrl = "/activity";
      let editUrl = "/activity";
      let removeUrl = "/activity";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        searchOnInit: true,
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
              columnClass: "col-12 col-md-3",
            },
            {
              name: "teamCode",
              text: "Code",
              type: InputType.text,
              columnClass: "col-12 col-md-3",
            },
            {
              name: "university",
              text: "สถานศึกษา",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/universities",
              },
              columnClass: "col-12 col-md-6",
            },
            {
              name: "date",
              text: "",
              type: InputType.begin_end_date,
              rule: {
                // required: true,
              },
              default: {
                begin: "",
                end: "",
              },
              columnClass: "col-12 col-md-6",
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MENTOR],
        edit: [UserRole.ADMIN, UserRole.MENTOR],
        remove: [],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "teamCode",
            text: "Code",
            style: {
              width: "160px",
            },
          },
          {
            name: "teamName",
            text: "ทีม",
            style: {
              width: "160px",
            },
          },
          {
            name: "universityName",
            text: "สถานศึกษา",
            style: {
              width: "230px",
            },
          },
          {
            name: "name",
            text: "รายละเอียด",
            style: {
              width: "160px",
            },
          },
          {
            name: "issueDate",
            text: "วันที่",
            style: {
              width: "160px",
            },
          },
          {
            name: "cost",
            text: "ค่าใช้จ่าย",
            numeric: 0,
            style: {
              width: "160px",
            },
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              name: "name",
              text: "รายละเอียด",
              type: InputType.text,
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              name: "issueDate",
              text: "วันที่",
              type: InputType.datePicker,
              rule: {
                required: true,
              },
            },
            {
              name: "cost",
              text: "ค่าใช้จ่าย",
              type: InputType.number,
            },
            {
              name: "note",
              text: "หมายเหตุ",
              type: InputType.textArea,
            },
          ],

          // [
          //   {
          //     type: "section",
          //     text: "รูปบัตรนักศึกษา",
          //   },
          // ],
          // [
          //   {
          //     name: "imageList",
          //     default: [],
          //     type: InputType.memberGallery,
          //   },
          // ],
        ],
      };
    },
  },
};
</script>
